import axios from 'axios'

axios.interceptors.response.use(
  (res) => {

    if (res.data.code === '00000' || res.data.code === '0000') {
      return res.data
    } else {

      return Promise.reject(new Error(res.data.msg || 'Error'))
    }
  },
  (err) => {

    if (!err.response) {
      console.log('接口返回错误error信息', err)
      return Promise.reject(err)
    }
  }
)

export default axios
