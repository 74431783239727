import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: '/visual/overview',
    component: () =>
      import("../views/zhanyu/index.vue"),
  },
  {
    path: "/visual",
    name: "visual",
    component: () =>
      import("../views/zhanyu/index.vue"),
    redirect: '/visual/overview',
    children: [
      {
        path: "overview",
        name: "overview",
        component: () =>
          import("../views/zhanyu/children/digital-park.vue"),
      },
      {
        path: "zjl",
        name: "zjl",
        component: () =>
          import("../views/zhanyu/children/zjl.vue"),
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
