
import Vue from "vue";
import {
  Button,
  Loading,
  Message,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Dialog,
  Collapse,
  CollapseItem,
  Form,
  FormItem
} from 'element-ui';
import App from "./App.vue";
import router from "./router";

import axios from './axios/index'
Vue.prototype.$axios = axios

// 全局自定义变量、方法
import $ from './utils/methods/methods'
Vue.prototype.$ = $

Vue.component(Button.name, Button);
Vue.component(Dialog.name, Dialog);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(Form.name, Form);
Vue.component(FormItem.name, FormItem);

Vue.use(Loading.directive);
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)

Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;

import rem from './utils/rem/rem'
Vue.use(rem)

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
